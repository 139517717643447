import React, { useEffect, useState, useContext, useRef } from "react";
import styles from "../styles/Archivo.module.css";

import { faUpload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContratoContext } from "./ContratoContext";

import { toast } from "react-toastify";

export default function Archivo(props) {
  const { archivos, setArchivos } = useContext(ContratoContext);
  const confirmModal = props.onConfirm;
  const [uploaded, setUploaded] = useState(false);

  //const [texto, setTexto] = useState("");
  const id = props.id;
  const num = props.num;

  function handleFile(e) {
    setArchivos((prev) => {
      prev[id].file = e.target.files[0];
      return prev;
    });
    setUploaded(true);
  }

  function handleInput(e) {
    setArchivos((prev) => {
      prev[id].texto = e.target.value;
      return prev;
    });
  }

  function handleDelete(e, handleClose) {
    setArchivos(archivos.toSpliced(id, 1));
    toast.success("Archivo Eliminado", { className: styles.toastMessage });
    handleClose();
  }

  function handleConfirmModal() {
    //setOnDialog((prev) => {return {...prev, display: true, title: "Está seguro que desea eliminar este archivo?", handleConfirm: handleDelete}});
    confirmModal("¿Está seguro que desea eliminar este archivo?", handleDelete);
  }

  return (
    <div key={"ID" + num} className={styles.container}>
      <input
        type="file"
        key={"IF" + num}
        capture="environment"
        onChange={handleFile}
        className={`${styles.fileSelector} ${uploaded ? styles.uploaded : ""}`}
        accept="image/*,video/*"
      />
      <div className={styles.label}>Comentario: </div>
      <textarea
        key={"TF" + num}
        onChange={handleInput}
        className={styles.textarea}
      ></textarea>
      <button
        key={"BF" + num}
        className={styles.deleteButton}
        onClick={handleConfirmModal}
      >
        <FontAwesomeIcon icon={faTrash} />
      </button>
    </div>
  );
}
