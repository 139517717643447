import React, { useState, useEffect } from "react";
import styles from "../styles/Login.module.css";
import axios from "axios";
import { useSignIn } from "react-auth-kit";
import { useNavigate, useSearchParams } from "react-router-dom";
import MRCLogo from "../montevideo-rent-a-car-logo.png";
import cqsoft from "../cqsoft.png";
import waven from "../waven.png";
import logo from "../logo.png";

const Login = () => {
  const [isWrong, setIsWrong] = useState(false);
  const signIn = useSignIn();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("urlAuth") == "1") {
      let data = new FormData();
      data.append("user", searchParams.get("user"));
      data.append("pass", searchParams.get("pass"));
      data.append("modo", 0);

      axios.post("../api/login.php", data).then((res) => {
        if (res.data.status === "OK") {
          signIn({
            token: res.data.data,
            tokenType: "Bearer",
            expiresIn: 3600,
            authState: {},
          });
          navigate("/");
        } else {
          setIsWrong(true);
        }
      });
    }
  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    let data = new FormData(e.target);
    data.append("modo", 0);

    axios.post("../api/login.php", data).then((res) => {
      if (res.data.status === "OK") {
        signIn({
          token: res.data.data,
          tokenType: "Bearer",
          expiresIn: 3600,
          authState: {},
        });
        navigate("/");
      } else {
        setIsWrong(true);
      }
    });
  }
  //onChange={() => setIsWrong(false)}
  //{isWrong ? <h3>Usuario o Contraseña incorrectos</h3> : ""}

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <img src={MRCLogo} className={styles.logo} />
        <hr className={styles.hr} />
        <h4>Usuario</h4>
        <input type="text" name="user" autoComplete="on" />
        <h4>Contraseña</h4>
        <input type="password" name="pass" autoComplete="on" />
        <h4 style={isWrong ? { color: "red" } : { color: "red", opacity: 0 }}>
          Usuario o Contraseña Incorrectos
        </h4>

        <input type="submit" value="Iniciar Sesión" />
      </form>
      <div className={styles.branding}>
        <img src={cqsoft} />
        <img src={logo} />
        <img src={waven} />
      </div>
    </div>
  );
};

export default Login;
