import React, { useEffect, useState } from "react";
import styles from "../styles/ContratoForm.module.css";
import {
  faInfoCircle,
  faCheck,
  faDisplay,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmModal from "./ConfirmModal";
import { toast } from "react-toastify";

export default function ContratoForm(props) {
  const [comentario, setComentario] = useState(
    "El vehiculo se entrega y se devuelve con el tanque lleno. Tipo de combustible: Nafta Premium."
  );
  const [onDialog, setOnDialog] = useState({
    display: false,
    title: "",
    handleConfirm: false,
  });

  function handleSubmit(e) {
    e.preventDefault();
    const event = e;
    setOnDialog({
      display: true,
      title: "¿Está seguro que desea enviar y guardar este chequeo?",
      handleConfirm: (handleClose) => {
        props.onSubmit(event, handleClose);
      },
    });
  }

  return (
    <form className={styles.container} onSubmit={handleSubmit}>
      <div className={styles.formLine}>
        <FontAwesomeIcon className={styles.icon} icon={faInfoCircle} />

        <h4>Comentario general:</h4>
        <input
          type="text"
          name="comentario"
          className={styles.inputComentario}
          placeholder="Comentario"
          onChange={(e) => setComentario(e.target.value)}
          value={comentario}
          required
        />
      </div>
      <div className={styles.formLine}>
        <h4>Fecha y Hora</h4>
        <input
          type="datetime-local"
          defaultValue={new Date(new Date().setHours(new Date().getHours() - 3))
            .toISOString()
            .substring(0, 16)}
          name="fechahora"
          style={{ height: "1cm" }}
          required
        />
        <h4>Kilometraje</h4>
        <input
          type="number"
          name="kms"
          className={styles.input}
          placeholder="Kilometraje"
          required
        />
        <h4>Combustible</h4>
        <input
          type="text"
          name="combustible"
          placeholder="Combustible"
          className={styles.input}
          required
        />
      </div>
      <div className={styles.formLine}>
        <h4>Gato</h4>
        <input type="checkbox" name="gato" />
        <h4>Herramientas</h4>
        <input type="checkbox" name="herramientas" />
        <h4>Tazas</h4>
        <input type="checkbox" name="tazas" />
        <h4>Bomberito</h4>
        <input type="checkbox" name="bomberito" />
      </div>
      <div className={styles.formLine}>
        <h4>Baliza</h4>
        <input type="checkbox" name="baliza" />
        <h4>Botiquin</h4>
        <input type="checkbox" name="botiquin" />
        <h4>Radio</h4>
        <h5>Original</h5>
        <input defaultChecked type="radio" name="radio" value="1" />
        <h5>Desmontable</h5>
        <input type="radio" name="radio" value="0" />
        <button className={styles.enviar}>
          <FontAwesomeIcon className={styles.icon} icon={faCheck} /> Enviar
        </button>
      </div>
      <ConfirmModal onConfirm={{ onDialog, setOnDialog }} />
    </form>
  );
}
