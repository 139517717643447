import React, { useEffect } from "react";
import styles from "../styles/ConfirmModal.module.css";
import { faClose, faCheck, faDisplay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";

const ConfirmModal = ({ onConfirm }) => {
  const { onDialog, setOnDialog } = onConfirm;

  function handleConfirmClick() {
    /*setOnDialog((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });*/
    onDialog.handleConfirm(handleClose);
    handleClose();
    //handleClose();
  }
  function handleClose() {
    setOnDialog({ display: false, handleConfirm: false });
  }
  return (
    <div
      className={`${styles.container} ${
        onDialog.display ? styles.show : styles.hide
      }`}
    >
      <div className={styles.modal}>
        <div className={styles.close} onClick={handleClose}>
          <FontAwesomeIcon className={styles.closeButton} icon={faClose} />
        </div>
        <div className={styles.text}>{onDialog.title}</div>
        <div className={styles.buttons}>
          <button
            disabled={onDialog.isLoading}
            type="button"
            className={styles.confirm}
            onClick={handleConfirmClick}
          >
            <FontAwesomeIcon icon={faCheck} /> Confirmar
          </button>
          <button
            type="button"
            className={styles.cancel}
            disabled={onDialog.isLoading}
            onClick={handleClose}
          >
            <FontAwesomeIcon icon={faClose} /> Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
