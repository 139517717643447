import React, { useState } from "react";
import axios from "axios";
import styles from "../styles/ReenviarMail.module.css"; // Assuming you have a CSS file for styling
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

const ReenviarMail = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const { contrato, matricula } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData();
    data.append("modo", 1);
    data.append("email", email);

    data.append("contrato", contrato);
    data.append("matricula", matricula);

    axios.post("../../../api/contratos.php", data).then((res) => {
      if (res.data.status == "OK") {
        toast.success("Mail Enviado");
        navigate(-1);
      } else {
        toast.error("Ocurrió un error");
        navigate(-1);
      }
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.modalContainer}>
        <h2>Reenviar Archivos</h2>
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
          className={styles.form}
        >
          <label htmlFor="email">Mail:</label>
          <input
            type="email"
            id="email"
            className={styles.formInput}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit" disabled={loading}>
            {loading ? "Enviando..." : "Enviar"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ReenviarMail;
