import React from "react";
import styles from "../styles/TopWindowBar.module.css";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";

export default function TopWindowBar({
  title = "titulo",
  icon,
  backroute = "/",
}) {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <Link onClick={() => navigate(-1)} className={styles.backLink}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </Link>
      <h2>
        <FontAwesomeIcon className={styles.icon} icon={icon} />
        {title}
      </h2>
    </div>
  );
}
