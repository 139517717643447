import logo from "./logo.svg";
import "./App.css";
import Contrato from "./components/Contrato";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
  Navigate,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ListaContratos from "./components/ListaContratos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePen,
  faEye,
  faDoorOpen,
} from "@fortawesome/free-solid-svg-icons";
import MRCLogo from "./montevideo-rent-a-car-logo.png";
import {
  AuthProvider,
  RequireAuth,
  useIsAuthenticated,
  useSignIn,
} from "react-auth-kit";
import axios from "axios";

import Login from "./components/Login";
import Main from "./components/Main";
import { useEffect } from "react";
import ListaChequeosCerrados from "./components/ListaChequeosCerrados";
import CerrarChequeo from "./components/CerrarChequeo";
import VerChequeo from "./components/VerChequeo";
import ReenviarMail from "./components/ReenviarMail";

function App() {
  return (
    <AuthProvider
      authType={"cookie"}
      authName={"_auth"}
      cookieDomain={window.location.hostname}
      cookieSecure={false}
    >
      <div
        className="App"
        style={{
          backgroundColor: "#2b336b",
          width: "100vw",
          height: "100vh",
          display: "flex",
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              index
              element={
                <RequireAuth loginPath="/login">
                  <Main />
                </RequireAuth>
              }
            />
            <Route
              path="/crearChequeo/:contrato/:matricula"
              element={
                <RequireAuth loginPath="/login">
                  <Contrato />
                </RequireAuth>
              }
            />
            <Route
              path="/cerrarChequeo/:contrato/:matricula"
              element={
                <RequireAuth loginPath="/login">
                  <CerrarChequeo />
                </RequireAuth>
              }
            />
            <Route
              path="/verChequeo/:contrato/:matricula"
              element={
                <RequireAuth loginPath="/login">
                  <VerChequeo />
                </RequireAuth>
              }
            />
            <Route
              path="/listaChequeos"
              element={
                <RequireAuth loginPath="/login">
                  <ListaContratos />
                </RequireAuth>
              }
            />
            <Route
              path="/:contrato/:matricula/reenviarMail"
              element={
                <RequireAuth loginPath="/login">
                  <ReenviarMail />
                </RequireAuth>
              }
            />
            <Route
              path="/listaChequeosCerrados"
              element={
                <RequireAuth loginPath="/login">
                  <ListaChequeosCerrados />
                </RequireAuth>
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <ToastContainer />
        </BrowserRouter>
      </div>
    </AuthProvider>
  );
}

export default App;
