import React, { useState, useContext, useEffect } from "react";
import styles from "../styles/Contrato.module.css";
import ContratoForm from "./ContratoForm";
import { faFilePen } from "@fortawesome/free-solid-svg-icons";
import TopWindowBar from "./TopWindowBar";
import Archivos from "./Archivos";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ContratoContext } from "./ContratoContext";
import { toast } from "react-toastify";

export default function Contrato(props) {
  const navigate = useNavigate();
  let { contrato, matricula } = useParams();

  const [archivos, setArchivos] = useState([]);

  function handleSubmit(e, handleClose) {
    let data = new FormData(e.target);
    data.append("contrato", contrato);
    data.append("matricula", matricula);
    data.append(
      "fechahoraformato",
      data.get("fechahora").slice(0, 19).replace("T", " ")
    );
    console.log(data.get("fechahora").slice(0, 19).replace("T", " "));

    archivos.forEach((archivo, index) => {
      if (archivo.file != null) {
        data.append("archivo" + index, archivo.file);
      }
    });

    let archivosTXT = [];
    archivos.forEach((archivo) => {
      if (archivo.file != null) {
        archivosTXT.push(archivo.texto);
      }
    });

    data.append("archivosTXT", JSON.stringify(archivosTXT));
    //aca hay que ir a buscar los archivos de alguna manera y agregarlos al request
    e.preventDefault();
    const load = toast.loading("Cargando...");
    axios.post("../../api/insertContrato.php", data).then((response) => {
      toast.dismiss(load);

      if (response.data.status === "OK") {
        handleClose();
        toast.success("Contrato Enviado y Guardado");
        navigate("/");
      } else if (response.data.status === "DUPE") {
        toast.error("Contrato Duplicado");
        handleClose();
      } else {
        toast.error("Ha ocurrido un error: " + response.data);
        handleClose();
      }
    });
  }

  useEffect(() => console.log(archivos));
  return (
    <ContratoContext.Provider value={{ archivos, setArchivos }}>
      <div className={styles.container}>
        <div className={styles.main}>
          <TopWindowBar icon={faFilePen} title={"Crear Chequeo " + contrato} />
          <ContratoForm onSubmit={handleSubmit} />
          <Archivos />
        </div>
      </div>
    </ContratoContext.Provider>
  );
}
