
import React, { useState, useEffect, useContext } from "react";
import styles from "../styles/Archivos.module.css";
import Archivo from "./Archivo";

import { faAdd, faDisplay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContratoContext } from "./ContratoContext";
import ConfirmModal from "./ConfirmModal";

export default function Archivos(props) {

    //const [archivos, setArchivos] = useState();
    const {archivos, setArchivos} = useContext(ContratoContext);
    const [cantArchivos, setCantArchivos] = useState(0);
    const [onDialog, setOnDialog] = useState({display: false, title: "", handleConfirm: false});

    

    function agregarArchivo() {
        let archivo = {
            numero: cantArchivos,
            file: null,
            texto: null
        };
        
        setArchivos((prev) => {
            return [...prev, archivo];
        })
        setCantArchivos((p) => p + 1)
    }

    function confirmModal(titulo, confirmFunction){
        setOnDialog((prev) => {return {...prev, display: true, title: titulo, handleConfirm: (handleClose) => confirmFunction(null, handleClose)}});
    }

    return (<div className={styles.container}>
                {archivos.map((archivo, index) => {
                        return <Archivo key={archivo.numero} onConfirm={confirmModal} id={index} />;
                })}
                <button className={styles.addFileButton} onClick={agregarArchivo}> <FontAwesomeIcon className={styles.addFileIcon} icon={faAdd} /> Agregar Archivo</button>
                <ConfirmModal onConfirm={{onDialog, setOnDialog}}/>
            </div>)
}