import React, { useEffect, useState } from "react";
import styles from "../styles/ListaContratos.module.css";
import TopWindowBar from "./TopWindowBar";
import {
  faEye,
  faTrash,
  faShare,
  faArrowRight,
  faArrowLeft,
  faDownload,
  faSearch,
  faAdd,
  faCheck,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import ConfirmModal from "./ConfirmModal";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ListaChequeosCerrados = () => {
  const navigate = useNavigate();
  const [isFiltering, setIsFiltering] = useState(true);
  const [fechaDesde, setFechaDesde] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [fechaHasta, setFechaHasta] = useState(
    new Date().toISOString().substring(0, 10)
  );
  useEffect(() => {
    updateData();
  }, [fechaDesde, fechaHasta, isFiltering]);

  function updateData() {
    setIsLoading(true);
    axios.get("../api/contratos.php?tipo=1").then((res) => {
      if (res.data.status === "OK") {
        setIsLoading(false);
        let filteredData = res.data.data;
        if (isFiltering) {
          filteredData = res.data.data.filter((linea) => {
            linea.DHEntrada = new Date(linea.DHEntrada.substring(0, 10));
            linea.DHEntrada.setHours(0, 0, 0, 0);

            let fechaDesdeCero = new Date(fechaDesde);
            fechaDesdeCero.setHours(0, 0, 0, 0);

            let fechaHastaCero = new Date(fechaHasta);
            fechaHastaCero.setHours(0, 0, 0, 0);
            return (
              linea.DHEntrada <= fechaHastaCero &&
              linea.DHEntrada >= fechaDesdeCero
            );
          });
        }
        setData(filteredData);
      } else {
        toast.error("Ha ocurrido un error actualizando: " + res.data.error);
      }
    });
  }

  function confirmModal(title, confirmFunction) {
    setOnDialog({
      display: true,
      title: title,
      handleConfirm: confirmFunction,
      isLoading: false,
    });
  }

  function closeModal() {
    setOnDialog({
      display: false,
      title: "",
      handleConfirm: false,
    });
  }

  const [onDialog, setOnDialog] = useState({
    display: false,
    title: "",
    handleConfirm: false,
  });

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    {
      header: "Contrato",
      accessorKey: "Contrato",
      cell: (props) => <p key={"con" + props.id}>{props.getValue()}</p>,
    },
    {
      header: "Nombre",
      accessorKey: "Nombre",
      cell: (props) => <p key={"nombre" + props.id}>{props.getValue()}</p>,
    },
    {
      header: "Mail",
      accessorKey: "Email",
      cell: (props) => <p key={"mail" + props.id}>{props.getValue()}</p>,
    },
    {
      header: "Matrícula",
      accessorKey: "Matricula",
      cell: (props) => <p key={"mat" + props.id}>{props.getValue()}</p>,
    },
    {
      header: "Dif. Kms",
      accessorKey: "Kms",
      cell: (props) => <p key={"kms" + props.id}>{props.getValue()}</p>,
    },
    {
      header: "Creado",
      accessorKey: "Creado",
      cell: (props) => (
        <p key={"crea" + props.id}>
          {new Date(props.getValue() + "T00:00:00").toLocaleDateString("es-UY")}
        </p>
      ),
    },
    {
      header: "Chequeado",
      accessorKey: "Chequeado",
      cell: (props) => <p key={"crea" + props.id}>{props.getValue()}</p>,
    },
    {
      header: "Acciones",
      cell: ({ row }) => (
        <div className={styles.buttonContainer}>
          <button
            className={styles.tableButton + " " + styles.yellowButton}
            onClick={() =>
              navigate(
                "/verChequeo/" +
                  row.original.Contrato +
                  "/" +
                  row.original.Matricula
              )
            }
          >
            <FontAwesomeIcon className={styles.icon} icon={faEye} /> Ver Chequeo
          </button>
        </div>
      ),
    },
  ];

  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: 6, //default page size
  });
  const [filtering, setFiltering] = useState("");

  const tabla = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination, //update the pagination state when internal APIs mutate the pagination state
    onGlobalFilterChange: setFiltering,
    state: {
      //...
      pagination,
      globalFilter: filtering,
      columnVisibility: {
        Chequeado: false,
      },
    },
  });
  return (
    <div className={styles.container}>
      {isLoading ? (
        <div className={styles.screen}>
          <span className={styles.loader}></span>
        </div>
      ) : (
        ""
      )}
      <div className={styles.main}>
        <TopWindowBar title="Ver Chequeos Cerrados" icon={faEye} />
        <div className={styles.searchContainer}>
          <h4>Filtrar: </h4>
          <input
            type="checkbox"
            checked={isFiltering}
            onChange={() => setIsFiltering((prev) => !prev)}
          />
          <h4>Desde</h4>
          <input
            type="date"
            value={fechaDesde}
            onChange={(e) => setFechaDesde(e.target.value)}
          />
          <h4>Hasta</h4>
          <input
            type="date"
            value={fechaHasta}
            onChange={(e) => setFechaHasta(e.target.value)}
          />
          <FontAwesomeIcon className={styles.icon} icon={faSearch} />
          <input
            type="text"
            value={filtering}
            onChange={(e) => setFiltering(e.target.value)}
            placeholder="Buscar"
          />
        </div>

        <table className={styles.table}>
          <thead>
            {tabla.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>{header.column.columnDef.header}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {tabla.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.pagButtons}>
          <button
            className={styles.prevButton}
            disabled={!tabla.getCanPreviousPage()}
            onClick={() => tabla.previousPage()}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          {"Página " + (pagination.pageIndex + 1)}
          <button
            disabled={!tabla.getCanNextPage()}
            onClick={() => tabla.nextPage()}
            className={styles.nextButton}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </div>
      </div>
      <ConfirmModal onConfirm={{ onDialog, setOnDialog }} />
    </div>
  );
};

export default ListaChequeosCerrados;
