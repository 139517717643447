import React, { useEffect } from "react";
import "../App.css";
import styles from "../styles/Login.module.css";
import { Link, Outlet } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePen,
  faEye,
  faDoorOpen,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import MRCLogo from "../montevideo-rent-a-car-logo.png";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import cqsoft from "../cqsoft.png";
import waven from "../waven.png";
import logo from "../logo.png";
import axios from "axios";
import { toast } from "react-toastify";

const Main = () => {
  const logout = useSignOut();
  const navigate = useNavigate();

  useEffect(() => {
    //ingresar nuevos contratos
    const data = new FormData();
    data.append("modo", 3);
    axios.post("../api/contratos.php", data).then((res) => {
      if (res.data == "NEW") {
        toast.success("Nuevos contratos ingresados");
      }
    });
  });

  return (
    <div className="mainContainer">
      <Outlet />
      <div className="buttonsContainer">
        <img src={MRCLogo} className="logo" />
        <Link to="/listaChequeos" className="link">
          <FontAwesomeIcon className="icon" icon={faEye} />
          Ver Chequeos
        </Link>
        <Link to="/listaChequeosCerrados" className="link">
          <FontAwesomeIcon className="icon" icon={faEyeSlash} />
          Chequeos Cerrados
        </Link>

        <button
          onClick={() => {
            logout();
            const data = new FormData();
            data.append("modo", 1);
            axios.post("../api/login.php", data);
            navigate("/login");
          }}
          className="link salirButton"
        >
          <FontAwesomeIcon className="icon" icon={faDoorOpen} />
          Salir
        </button>
      </div>
      <div className={styles.branding}>
        <img src={cqsoft} />
        <img src={logo} />
        <img src={waven} />
      </div>
    </div>
  );
};

export default Main;
