import React, { useEffect, useState } from "react";
import styles from "../styles/CerrarChequeo.module.css";
import TopWindowBar from "./TopWindowBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const CerrarChequeo = () => {
  const navigate = useNavigate();
  const { contrato, matricula } = useParams();
  const [Kms, setKms] = useState(0);
  const [data, setData] = useState([]);
  useEffect(() => {
    const loading = toast.loading("Cargando...");
    axios
      .get(
        `../../api/contratos.php?contrato=${contrato}&matricula=${matricula}&tipo=2`
      )
      .then((res) => {
        toast.dismiss(loading);
        setData(res.data.data);
      });
  }, []);

  function handleCerrar(e) {
    e.preventDefault();
    const loading = toast.loading("Cargando...");
    const data = new FormData(e.target);
    data.append("modo", 4);
    data.append(
      "fechahoraformato",
      data.get("fechahora").slice(0, 19).replace("T", " ")
    );
    console.log(data.get("fechahora").slice(0, 19).replace("T", " "));
    data.append("contrato", contrato);
    data.append("matricula", matricula);

    axios.post("../../api/contratos.php", data).then((res) => {
      toast.dismiss(loading);
      if (res.data.status == "OK") {
        toast.success("Contrato Cerrado");
        navigate("/listaChequeos", {
          replace: true,
        });
      } else {
        toast.error("Ocurrió un error");
      }
    });
  }

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <TopWindowBar
          title={"Cerrar Chequeo " + contrato}
          icon={faCheckCircle}
          backroute="/listaChequeos"
        />
        <form className={styles.form} onSubmit={handleCerrar}>
          <h2>Datos de entrada</h2>

          <h4>Fecha y Hora</h4>
          <input
            type="datetime-local"
            name="fechahora"
            style={{ height: "1cm" }}
            defaultValue={new Date(
              new Date().setHours(new Date().getHours() - 3)
            )
              .toISOString()
              .substring(0, 16)}
            required
          />
          <h4>Kilometraje</h4>
          <input
            type="number"
            className={styles.formInput}
            placeholder="Kilometraje"
            name="kms"
            value={Kms}
            onChange={(e) => setKms(e.target.value)}
            required
          />
          <p>
            <h6>Salida: </h6>
            {data["KMSSalida"]}
          </p>
          <p>
            <h6>Diferencia: </h6>
            {Kms && Kms >= parseInt(data["KMSSalida"])
              ? Kms - parseInt(data["KMSSalida"])
              : "-"}
          </p>
          <h4>Combustible</h4>
          <input
            type="text"
            placeholder="Combustible"
            name="combustible"
            className={styles.formInput}
            required
          />
          <button>
            <FontAwesomeIcon icon={faCheck} />
            Cerrar
          </button>
        </form>
      </div>
    </div>
  );
};

export default CerrarChequeo;
